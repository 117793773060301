import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [

  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'ECHO',
    lpAddresses: {
      97: '0xAa56B5a641F2B5dDF7e8f014463111974AD2F73D',
      56: '0x4297Ec537AA6A024a95B7FDa29352c5bB8ec6c78',
      80001: "0x056DCB5Fe7645dcA56641C79DB39eB531f2c1af8",
      137: "0x0834FABA2BE9692af9aaD8EfA00dA36624e38603"
    },
    tokenSymbol: 'ECHO',
    tokenAddresses: {
      97: '0xAa56B5a641F2B5dDF7e8f014463111974AD2F73D',
      56: '0x6aaa14929d74b8533343c1a5b6e42443f59b6f6f',
      80001: "0x056DCB5Fe7645dcA56641C79DB39eB531f2c1af8",
      137: "0x7828bf1bdd19b457E08AB1AFcFBe716Bc0e3cEaC"
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },





  {
    pid: 1,
    risk: 5,
    lpSymbol: 'ECHO-USDT LP',
    lpAddresses: {
      97: '0xAa56B5a641F2B5dDF7e8f014463111974AD2F73D',
      56: '0x4297Ec537AA6A024a95B7FDa29352c5bB8ec6c78',
      80001: "0x056DCB5Fe7645dcA56641C79DB39eB531f2c1af8",
      137: "0x0834FABA2BE9692af9aaD8EfA00dA36624e38603"
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xAa56B5a641F2B5dDF7e8f014463111974AD2F73D',
      56: '0x6aaa14929d74b8533343c1a5b6e42443f59b6f6f',
      80001: "0x056DCB5Fe7645dcA56641C79DB39eB531f2c1af8",
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },



]

export default farms
